@import "shared.scss";

$theme-colors: (
    "primary": $color-accent,
    "dark": $color-primary,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
}

.navbar {
    padding: 0;
}
.navbar-dark .navbar-toggler {
    border: none;
    outline: none;
}
.dropdown:hover .dropdown-menu {
    display: block;
}
.navbar-nav .dropdown-menu {
    .dropdown-item {
        color: $color-darkbg-text;
    }
}

.dropdown:has(.active) .dropdown-toggle {
    background-color: $color-accent;
}
.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item:focus {
    background: $color-gray-accent;
    color: $color-black !important;
}
.nav-item {
    .nav-link {
        padding: 5px;
        &.active {
            font-weight: bold;
            background-color: $color-accent;
        }
    }
}
.nav-tabs {
    border-bottom: none;
    .nav-item {
        border-color: #e9ecef #e9ecef #dee2e6;
        margin-right: 4px;
        text-align: center;
        .nav-link {
            padding: 0.5em 1em;
            &.active {
                color: #fff;
            }
        }
        &.active {
            color: #fff;
        }
    }
}
.btn-link,
a {
    color: #007bff;
}
.navbar-collapse {
    padding: 10px;
}

@include media-breakpoint-up(lg) {
    .signature {
        max-width: 150px !important;
    }

    .navbar-collapse {
        padding: 0;
    }
    .navbar-nav {
        .nav-item {
            width: 150px;
            text-align: center;
            .nav-link {
                padding: 5px 0;
                border-right: solid 1px $color-gray;
            }
        }
    }
    .dropdown-menu.show {
        display: none;
    }
}

.table .thead-dark th {
    position: relative;
    padding-right: 24px;
}
.nav-tabs .nav-link.disabled {
    border: 1px solid #ccc;
}
.nav-tabs:hover .nav-link.disabled {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #6c757d;
}
mark,
.mark {
    padding: 0.2em 0 !important;
}
.card-header {
    font-weight: bold;
}
.rbt.searchcombobox,
.rbt {
    .form-control {
        padding-right: 35px !important;
    }
}
.was-validated select.form-control:invalid,
select.form-control.is-invalid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center !important;
}
.was-validated .searchcombobox .form-control:valid,
.form-control.is-valid {
    background-position: right calc(0.375em + 1.5rem) center !important;
    padding-right: 52px !important;
}
.was-validated .rbt.has-aux .form-control:valid,
.form-control.is-valid {
    background-position: right calc(0.375em + 1.5rem) center !important;
    padding-right: 52px !important;
}
.timeRangePickers,
.timePicker {
    label {
        margin-top: 10px;
        padding: 0 3px;
    }
}
.was-validated .timeRangePickers .form-control:invalid,
.timeRangePickers .form-control.is-invalid {
    background-position: right 1rem center !important;
}
.was-validated .timePicker .form-control:invalid,
.timePicker .form-control.is-invalid {
    background-position: right 1rem center !important;
}
.was-validated .timeRangePickers .form-control:valid,
.timeRangePickers .form-control.is-valid {
    background-position: right 1rem center !important;
}
.was-validated .timePicker .form-control:valid,
.timePicker .form-control.is-valid {
    background-position: right 1rem center !important;
}
.was-validated .timeRangePickers select.form-control:valid,
.timeRangePickers select.form-control.is-valid {
    padding-right: 1rem !important;
}
.was-validated .timePicker select.form-control:valid,
.timePicker select.form-control.is-valid {
    padding-right: 1rem !important;
}
.was-validated .timeRangePickers select.form-control:invalid,
.timeRangePickers select.form-control.invalid {
    padding-right: 1rem !important;
}
.was-validated .timePicker select.form-control:invalid,
.timePicker select.form-control.invalid {
    padding-right: 1rem !important;
}

@include media-breakpoint-only(lg) {
    .timeRangePickers {
        .form-control {
            font-size: 12px;
        }
    }
    .timePicker {
        .form-control {
            font-size: 12px;
        }
    }
}
