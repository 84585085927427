*,
.btn {
    line-height: normal !important;
}
body {
    background: transparent !important;
}

.logo {
    height: 55px;
    margin: 10px;
}
.table {
    th svg {
        position: absolute;
        right: 4px;
        margin-top: 2px;
    }
    td {
        font-size: 14px;
        word-wrap: break-word;
        .btn-link {
            padding: 0;
        }
    }
}
.datatable-header {
    padding: 10px 10px 0 10px;
    position: relative;
    .table-dnd-entries {
        position: absolute;
        left: 10px;
        top: 10px;
    }
    .form-control {
        height: calc(1.5em + 0.2rem + 3px);
    }
}
.datatable-footer {
    padding: 0 10px 10px 10px;
    .footer-links a {
        margin-left: 20px;
    }
    .footer-links button {
        margin-left: 20px;
        padding: 0;
    }
}
.datatable-body table {
    margin: 0;
    .is-changed {
        color: #ff6600;
        font-weight: bold;
    }
    .sortOrder {
        font-size: 16px;
        color: #009900;
        font-weight: bold;
        text-align: center;
    }
}
ul.pagination {
    margin-bottom: 0;
}
.page-link {
    padding: 0.2rem 0.4rem !important;
}
.form-control-file {
    background: #ffffff;
    border: 1px solid #b3b3b3;
    padding: 3px;
    border-radius: 0.25rem;
}
.centered {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
label.req {
    margin-right: 3px;
    position: relative;
    padding-right: 10px;
}
.codeTitle {
    line-height: normal;
}

.copyright {
    text-align: center;
    color: #000000;
    line-height: 14px;
    font-size: 10px;
}
.poweredByViamente {
    color: #000000;
    font-size: 10px;
    text-align: center;
    padding-left: 20px;
}
a.bluelink {
    color: #4986ff;
    cursor: pointer;
}
.invalid-feedback {
    color: #cc0000;
    font-size: 12px;
}

.finePrint {
    font-size: 0.9em;
    line-height: 1.2em;
    color: #7f7f7f;
}
.upload-select option.required {
    color: #cc0000;
    font-weight: bold;
}
.webuploader-summary {
    margin: 0px;
    font-size: 1.5em;
    font-style: bold;
    color: #009900;
}
.uploaderTable table thead td {
    font-size: 0.8em;
    font-weight: bold;
}
.error {
    padding-bottom: 10px;
    padding-top: 3px;
    font-size: 12px;
    text-align: center;
    color: #cc0000;
}
.uploaderTable .table-striped tbody tr td.error {
    background-color: #f6e4ec;
    text-align: left;
    font-weight: bold;
    padding: 5px 5px 4px;
    cursor: help;
}
#webuploader-column-required {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}
.maxRoutes {
    margin-top: 10px;
    margin-bottom: 0;
}
.rightAddButton {
    padding-top: 0 !important;
}

/*
Resizable component
*/
.react-resizable {
    margin-bottom: 20px;
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-size: cover;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: -20px;
    transform: rotate(45deg);
}
.customer-td {
    width: 50%;
}
.route-td {
    width: 25%;
}
.remainingCustomer-td {
    width: 75%;
}

.recentDeliveryTable {
    button {
        font-size: 15px;
        padding: 0;
    }
    td {
        padding: 0 !important;
    }
}
.resize-wrapper {
    overflow: auto;
    border: 1px solid rgb(238, 238, 238);
}

.completionStatusTitle {
    color: #009900;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    margin-top: 10px;
}
.completionStatusText {
    color: #009900;
    font-size: 13px;
    font-weight: bold;
    margin-top: -3px;
}
.progressTextSummary {
    color: #009900;
    font-size: 17px;
    font-weight: bold;
    line-height: normal;
}

.lastDelivery button {
    padding: 0;
    line-height: normal;
}
.departureTime {
    color: #007bff;
    text-decoration: none;
}
.colorPicker {
    .swatch {
        padding: 5px;
        background: #fff;
        border-radius: 1px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        display: inline-block;
        cursor: pointer;
    }
    .colorBox {
        width: 36px;
        height: 14px;
        border-radius: 2px;
    }
    .popOver {
        position: absolute;
        z-index: 2;
    }
    .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
}

.modal-title {
    overflow-wrap: break-word;
    width: 100%;
    hyphens: auto;
}
.form-group {
    margin-bottom: 5px !important;

    label.required {
        color: red;
    }
}
.input-group > .rbt {
    flex: 1;
}

.rbt .toggle-button {
    background: transparent;
    border: 0;
    bottom: 0;
    padding: 0 12px;
    position: absolute;
    right: 0;
    top: 0;
}
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
}
.rbt-aux .rbt-close {
    margin-top: -4px;
    pointer-events: auto;
}

.modal-body {
    .card {
        border: none;
    }
}

.systemNotifications {
    position: fixed;
    bottom: 10px;
    right: 30px;
    width: 250px;
    z-index: 9999;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    cursor: not-allowed;
}
.toast {
    background-color: transparent !important;
}
.toast-header {
    color: #fff !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.toast.alert-warning {
    background-color: #fff3cd !important;
}
.toast.alert-success {
    background-color: #d4edda !important;
}
.toast-body {
    word-break: break-word;
}

.long-checkbox {
    cursor: pointer;
    padding: 5px 5px 2px 5px;
    border-radius: 0.25rem;
    margin: -1px 0 0 0;
    position: relative;
    &.enabled.unchecked:hover {
        background-color: #fff3cd;
        border-color: #ffeeba;
    }
    &.enabled.checked:hover {
        background-color: #e0f5cb;
        border-color: #c3e6cb;
    }
    &.checked {
        font-weight: bold;
        color: #000;
        background-color: #d4edda;
        border: solid 1px #c3e6cb;
        svg {
            color: #155724;
        }
    }
    &.unchecked {
        font-weight: normal;
        background-color: #fefefe;
        border: solid 1px #ccc;
        color: #000;
        svg {
            color: #721c24;
        }
    }
    svg {
        width: 1.5em;
        height: 1.5em;
    }
    &.disabled {
        opacity: 0.5;
        cursor: auto;
    }
    .rightIcon {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .leftText {
        padding-right: 1.5em;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.distributionCenterCheckList {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}
.btn {
    svg {
        margin-top: -3px;
    }
}
.was-validated .invalid ~ .invalid-feedback {
    display: block;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.validation-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.warning-message {
    svg {
        color: #cc0000;
    }
}
.uploaderTable {
    overflow: auto;
}
.routeStatus .progress-bar {
    background: #009900 !important;
}
#webuploader-msg {
    font-size: 1.4em;
    color: #009900;
    font-weight: bold;
    margin-top: 10px;
}
.help {
    color: #75c4f8;
    cursor: help;
}
.input-group-append label {
    margin: auto;
}
.expandedRow,
.selected:not(.multiple .selected),
.table-striped tbody tr:nth-of-type(2n + 1).selected:not(.multiple .selected),
.table-striped tbody tr:nth-of-type(2n + 1).expandedRow {
    background-color: #ffffe9;
}
.selectable {
    cursor: pointer;
}

.driverphoto-thumb {
    width: 50px;
    cursor: pointer;
}
.imageModal {
    position: relative;
    display: table;
    overflow: auto;
    width: auto;
    min-width: 225px;
}

.siteItem {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 5px;
    &:nth-child(even) {
        background-color: white;
    }
    &:nth-child(odd) {
        background-color: #e2e4ff;
    }
    .siteLeft {
        flex: auto;
    }
    .siteRight {
        flex: auto;
        text-align: right;
    }
    &.selected {
        background-color: #ffffe9;
    }
}
.siteAddress {
    display: flex;
    flex-direction: row;
    padding: 5px;
    .siteAddressLeft {
        flex: auto;
    }
    .siteAddressRight {
        flex: auto;
        text-align: right;
        svg {
            cursor: pointer;
            color: #4986ff;
        }
    }
}
#siteLocation {
    font-size: 14px;
    font-weight: bold;
    color: rgb(255, 117, 26);
}
.windowTransition {
    margin-top: 5px;
    display: block;
}
.capitalized {
    text-transform: capitalize;
}
.input-group button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/*Datepicker styles*/
.react-datepicker__header__dropdown {
    padding: 5px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    top: 0;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    bottom: 0;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: block;
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 10;
}
.react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px;
}
.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px;
}
.react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^="right"] {
    padding-left: 8px;
}
.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}
.react-datepicker-popper[data-placement^="left"] {
    padding-right: 8px;
}
.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}
.react-datepicker__navigation--previous {
    left: 2px;
}
.react-datepicker__navigation--next {
    right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 85px;
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
}
.react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
}
.react-datepicker__navigation-icon--next {
    left: -2px;
}
.react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
}
.react-datepicker__navigation-icon--previous {
    right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
}
.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: auto;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
    margin-left: 5px;
    display: inline-block;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
}
.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-names {
    margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}
.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
    color: #ccc;
    pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
    background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
    color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
    color: green;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: #f0f0f0;
    color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -16px;
    top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #6c757d;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}
.was-validated .form-control:valid + .react-datepicker__close-icon::after {
    margin-right: 28px;
    float: right;
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}
.centerVertical {
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelMargin {
    margin-top: 27px;
}
.modal-70vw {
    min-width: 70vw;
}
.modal-90vw {
    min-width: 90vw;
}
.prodChanged,
.routeChanged,
.deliveryDateChanged {
    color: rgb(255, 117, 26) !important;
}

ul.fileList {
    margin: 10px;
    list-style-type: none;
    padding: 0px;
}
.bullets {
    padding: 0 10px;
}
.external svg {
    font-size: 10px;
}
.fileList button {
    padding: 0;
    margin: 0;
}
td button.editEmpty {
    display: none;
}
tr:hover button.editEmpty {
    display: block;
}
.routeColor {
    border: solid 1px #000;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 5px;
}
.assetRoute {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.table-hover tbody tr:hover {
    background-color: #ffffe9 !important;
}
div.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.emptyData td {
    text-align: center;
}
button.overridden {
    color: rgb(255, 117, 26);
}
.greenStatus {
    color: #009900 !important;
}
.columnDropdown {
    padding: 0 10px;
}

.breadcrumb-item {
    button {
        padding: 0;
    }
    span {
        margin-top: 1px;
        display: inline-block;
    }
}
.pvaTable {
    thead th {
        text-align: center;
    }
    table {
        border: 1px solid #dee2e6 !important;
    }
    .table-striped tbody tr:nth-of-type(2n + 1) {
        background-color: #ffffff;
    }
    .fullWidth {
        background-color: rgba(0, 0, 0, 0.05);
    }
    .table-bordered {
        th,
        td {
            border: 0;
        }
    }
    .planActual {
        text-align: right;
    }
    .stopCount,
    .driveTime,
    .driveMiles,
    .stopTime,
    .routeTime {
        text-align: center;
    }

    .rowPlanned,
    .rowPlan {
        color: #999999 !important;
    }
    .exceptionGood {
        color: #18bb05 !important;
        font-weight: bold;
    }

    .rowPlanned {
        color: #999999;
    }
    td,
    th {
        padding: 5px !important;
    }
    tfoot {
        tr.rowActual th {
            font-weight: bold;
        }
        tr.rowPlan th {
            font-weight: normal;
        }
    }
}
.exceptionBad {
    color: #ff0000;
    font-weight: bold;
}
.stickyTable {
    position: relative;
    max-height: 100vh;
    thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    tfoot {
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
}
.routeListContainer,
.loadersListContainer {
    max-height: 300px;
    overflow: auto;
    padding-top: 1px;
}
table .number {
    text-align: right;
}
#printMe {
    background: #fff;
}
.checkboxFull {
    margin-bottom: 10px;
}

.optimized {
    color: #0066ff;
    font-weight: bold;
}
td.timebetween {
    font-weight: bold;
    text-align: center;
}
.licenseOn {
    background-color: #d3eeba !important;
}
.redPlaceHolder::placeholder {
    color: #dc3545 !important;
}
.block-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: 9999;
}
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 200px;
    height: 145px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
}
.loading-container.with-message {
    height: 200px;
}
.logoutContainer {
    margin: 10px;
    font-size: 14px;
    span {
        display: inline-block;
    }
    button {
        padding: 0;
    }
}
.messagesContainer {
    .alert {
        flex-direction: column;
        display: flex;
        svg {
            margin-right: 8px;
            align-self: center;
        }
        button {
            display: block;
            white-space: nowrap;
        }
    }
}

@media (min-width: 576px) {
    .logoutContainer {
        float: right;
    }
    .messagesContainer {
        .alert {
            flex-direction: row;

            button {
                margin-left: auto;
            }
        }
    }
}

table.loading {
    th,
    td {
        padding: 10px;
        text-align: left;
        vertical-align: top;
    }
}
#loginContainer {
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    border: #999999 solid 6px;
}

.login-template {
    label {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
        color: #979797;
    }
    #footer-wrapper,
    .copyright {
        text-align: center;
        font-size: 11px;
        line-height: normal;
    }
    #footer-wrapper {
        margin-top: 20px;
    }
    .copyright {
        display: inline-block;
    }
    .poweredByViamente {
        text-align: justify;
        font-size: 11px;
        padding-left: 0;
    }
}
.techsupport {
    font-size: 12px;
}
.sort-container tbody tr {
    cursor: move;
}
div#userwarning {
    height: 600px;
    overflow: auto;
    margin: 10px 0;
    border: 1px solid #cccccc;
    padding: 5px;
}
.margin-10 {
    margin: 10px;
}
.char-counter {
    float: right;
    color: #999999;
    font-size: 0.85em;
}
.expandedRow .details > .row {
    margin: 0;
}
@media print {
    .report-header {
        display: none !important;
    }
    .datatable-header {
        display: none !important;
    }
    .datatable-pagination-header {
        display: none !important;
    }
    .datatable-footer {
        display: none !important;
    }
    #footer-wrapper {
        display: none !important;
    }
    .logo-link {
        display: none !important;
    }
    table thead th svg {
        display: none !important;
    }
    table tbody td .btn-link {
        color: #000 !important;
    }
    .status-buttons {
        display: none !important;
    }
    .expandedRow {
        .detail-col-1,
        .detail-col-2,
        .detail-col-3,
        .detail-col-4 {
            max-width: 25%;
        }
    }
    .stickyTable {
        max-height: none !important;
    }
    .deliveryDetail {
        display: none !important;
    }
    .threeDots {
        display: none !important;
    }
    .optimizationDetailsLink {
        display: none !important;
    }
    .qtyScannedLink {
        color: #000 !important;
    }
    .viewTripButton {
        display: none !important;
    }
}
.timepicker-popup {
    z-index: 9999;
    position: absolute;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 130px;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    margin-top: -2px;
}

.timepicker-time {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 18px;
    margin-right: 5px;
    .time-rows {
        button {
            font-size: 22px;
            padding: 0;
            margin: 0;
            background-color: transparent;
            border: none;
        }
    }
    .hour-row {
        text-align: center;
        span {
            display: inline-block;
            margin: 2px 0;
        }
    }
}
.timepicker-ampm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
        display: inline-block;
        border: none;
        background: transparent;
        padding: 6px 8px;
        &:disabled {
            background: #e2e4ff;
            color: #000;
        }
    }
}
.timepicker-footer {
    display: flex;
    flex-direction: row;
    border-top: solid 1px rgba(0, 0, 0, 0.125);
    padding: 10px 0px;
    justify-content: space-between;
    margin-top: 10px;
}
.timepicker-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.transition-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
